:root {
    --extra-light-gray: #d0d0d0;
    --light-gray: #aaaaaa;
    --med-gray: #858585;
    --dark-gray: #444444;
    --coral: #ff928b;
    --orange: #ffb165;
    --green: #cfe561;
}

* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    position: relative;
}

body {
    overflow: hidden;
    font-family: Helvetica, Arial, sans-serif;
}

h1 {
    font-size: 1.5rem;
    margin-top: 30px;
}

p {
    margin: 10px 0;
}

svg {
    /* prevent mobile browser from interpreting touch events
       as pan/zoom, since we do that ourselves */
    touch-action: none;
}

svg text {
    cursor: default;
    user-select: none;
}

button {
    background-color: transparent;
    color: var(--dark-gray);
    border: none;
    cursor: pointer;
    outline: none;
    padding: 10px;
}

button:hover {
    color: var(--med-gray);
  }

input[type="range"] {
    vertical-align: middle;
}

label {
    vertical-align: middle;
    font-size: 20px;
    padding: 10px;
}

#play-pause-button {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 1;
    font-size: 3rem;
}

#speed-slider {
    position: absolute;
    top: 80px;
    left: 30px;
    z-index: 1;
}

#info-button {
    position: absolute;
    bottom: 10px;
    right: 20px;
    z-index: 1;
    font-size: 4rem;
}

#info-box {
    background-color: white;
    position: absolute;
    right: 80px;
    bottom: 50px;
    z-index: 1;
    width: calc(80vw - 80px);
    max-height: calc(100vh - 150px); 
    max-width: 600px; 
    border: 2px solid black;
    border-radius: 10px;
    padding: 50px 50px 0px 50px;
    overflow-y: auto;
}

@media screen and (max-width: 768px) {
    #info-button {
        right: 10px;
        bottom: 5px;
    }

    #info-box {
        width: calc(80vw - 50px);
        height: calc(95vh - 200px);
        right: 10px;
        bottom: 100px;
  }
}

#exit-info-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2rem;
}

table {
    text-align: center;
    border-collapse: collapse;
    width: 100%;
}

th {
    background-color: var(--extra-light-gray);
    border: 1px solid black;
    padding: 10px;
}

td {
    border: 1px solid black;
    padding: 10px;
}

.subscript {
    vertical-align: sub;
    font-size: smaller;
}

.centered-content {
    display: flex;
    justify-content: center;
}

.info-box-image {
    width: 200px;
    margin-bottom: 5px;
}

.fa-square-github {
    color: var(--dark-gray);
    padding: 10px;
    font-size: 3rem;
    margin-top: 80px;
    margin-bottom: 10px;
}

.fa-square-github:hover {
    color: var(--med-gray);
}
